<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppUnsubscribes />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppUnsubscribes from "@/components/Users/AppUnsubscribes";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppUnsubscribes = defineAsyncComponent(() =>
  import('@/components/Users/AppUnsubscribes')
)
export default {
  name: "Unsubscribes",
  components: {
    AppHeader,
    AppUnsubscribes,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>